import { useCallback, useContext, useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { IBuildWatershedFormProps } from "./BuildWatershedForm";
import Config from "../../../config/Config";
import { ButtonComponent } from "../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import { Icon, Table } from "semantic-ui-react";
import WatershedReportForm from "../../../models/WatershedReportForm";
import { useAPIFetchAllLocal } from "../../../core/UseAPILocal";
import JurisdictionGroup from "../../../models/JurisdictionGroup";
import WatershedReportJurisdictionSignOff from "../../../models/WatershedReportJurisdictionSignOff";
import WGReportingViewModel from "../../../models/viewModels/WGReportingViewModel";
import { GlobalAuthInfoContext } from "../../mainWrapper/MainWrapper";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Download from "../../../core/DownloadUtil";
import { WatershedGroupReportingPublicOutputs } from "./WatershedGroupReportingPublicOutputs/WatershedGroupReportingPublicOutputs";
import './BuildWatershedForm.scss';
import { useAuth } from "react-oidc-context";

export interface IWGReportingOutputs extends Omit<IBuildWatershedFormProps, "reportingPeriod" | "watershed"> {
    jurisdictionGroups: JurisdictionGroup[];
    scrollFunction: Function;
}

export const WGReportingOutputs: React.FC<IWGReportingOutputs> = (props: IWGReportingOutputs) => {
    const auth = useAuth();
    const [showOutputs1, setShowOutputs1] = useState<boolean>(false);
    const [timeStampOfGen, setTimeStampOfGen] = useState<string>('');

    const [excelOutputEndpoint, setExcelOutputEndpoint] = useState<string>();
    const [zipOutputEndpoint, setZipOutputEndpoint] = useState<string>();
    const [wordOutputEndoint, setWordOutputEndpoint] = useState<string>();

    const globalAuthInfoContext = useContext(GlobalAuthInfoContext);

    const [filteredJurGroups, setFilteredJurGroups] = useState<JurisdictionGroup[]>(new Array<JurisdictionGroup>());
    const [watershedReportForms, setWatershedReportForms] = useState<WatershedReportForm[]>(new Array<WatershedReportForm>());
    const [fetchForms, setFetchForms] = useState<boolean>(true);
    const [fetchReportSignature, setFetchReportSignature] = useState<boolean>(true);

    const [reportSignatures, setReportSignature] = useState<WatershedReportJurisdictionSignOff[]>(new Array<WatershedReportJurisdictionSignOff>());

    const [tableReportForms, setTableReportForms] = useState<WGReportingViewModel[]>(new Array<WGReportingViewModel>());

    useAPIFetchAllLocal(auth.user?.access_token, new WatershedReportForm(), fetchForms, setFetchForms, setWatershedReportForms, { 'watershedReportingPeriodId': props.selectedParams.reportingPeriodId, "watershedManagementGroupId": props.selectedParams.watershedGroupId, "active": 1 }, (data) => {
    });

    useAPIFetchAllLocal(auth.user?.access_token, new WatershedReportJurisdictionSignOff(), fetchReportSignature, setFetchReportSignature, setReportSignature, { 'watershedReportingPeriodId': props.selectedParams.reportingPeriodId, "active": 1 }, (data) => {
    });

    useEffect(() => {
        setFetchForms(true);
        setFetchReportSignature(true);
    }, [props.selectedParams])
    useEffect(() => {
        let filteredList: JurisdictionGroup[] = props.jurisdictionGroups.filter((item) => item.watershedManagementGroupId === props.selectedParams.watershedGroupId);
        setFilteredJurGroups(filteredList);
    }, [props.jurisdictionGroups, props.selectedParams]);

    useEffect(() => {
        let formList: WGReportingViewModel[] = new Array<WGReportingViewModel>();

        if (filteredJurGroups && (!fetchForms)) {
            filteredJurGroups.forEach((jurGroup) => {
                let form: WatershedReportForm = watershedReportForms.filter((item) => item.jurisdictionId === jurGroup.jurisdictionId)[0];
                if (form && form.id) {
                    let newItem: WGReportingViewModel = new WGReportingViewModel();
                    newItem.jurisdictionId = form.jurisdictionId;
                    let signature = reportSignatures.filter((sign) => sign.jurisdictionGroupId === jurGroup.id)[0];
                    if (signature && signature.id) {
                        newItem.signed = true;
                        newItem.signDate = new Date(signature.updated);
                    } else {
                        newItem.signed = false;
                        newItem.signDate = null;
                    }
                    formList.push(newItem);
                }
                else {
                    let newForm: WGReportingViewModel = new WGReportingViewModel();
                    newForm.jurisdictionId = jurGroup.jurisdictionId;
                    newForm.signed = false;
                    newForm.signDate = null;
                    formList.push(newForm);
                }
            })

            formList = formList.sort((a, b) => {
                if (props.lookups.getJurisdictionById(a.jurisdictionId) < props.lookups.getJurisdictionById(b.jurisdictionId)) { return -1; }
                if (props.lookups.getJurisdictionById(a.jurisdictionId) > props.lookups.getJurisdictionById(b.jurisdictionId)) { return 1; }
                return 0
            })
        }

        setTableReportForms(formList);
    }, [watershedReportForms, filteredJurGroups, fetchForms, reportSignatures, props.lookups]);

    useEffect(() => {
        setExcelOutputEndpoint(Config.api_endpoint + "report/excel/group/" + props.selectedParams.watershedGroupId + "/" + props.selectedParams.reportingPeriodId);
        setZipOutputEndpoint(Config.api_endpoint + "zip/group/" + props.selectedParams.watershedGroupId + "/" + props.selectedParams.reportingPeriodId);
        setWordOutputEndpoint(Config.api_endpoint + "report/word/group/" + props.selectedParams.watershedGroupId + "/" + props.selectedParams.reportingPeriodId);
    }, [props.selectedParams]);

    const getGroupFileName = useCallback((extension: string) => {
        var name = props.lookups.getWatershedManagementGroupById(props.selectedParams.watershedGroupId);
        name += " " + props.lookups.getReportingPeriodById(props.selectedParams.reportingPeriodId);
        name = name.replaceAll("-", "_").replaceAll("/", "-");
        name += extension;

        return name;
    }, [props.lookups, props.selectedParams]);

    const getJurisdictionFileName = useCallback((jurisdictionId: number, extension: string) => {
        var name = props.lookups.getJurisdictionById(jurisdictionId);
        name += " " + props.lookups.getWatershedManagementGroupById(props.selectedParams.watershedGroupId);
        name += " " + props.lookups.getReportingPeriodById(props.selectedParams.reportingPeriodId);
        name = name.replaceAll("-", "_").replaceAll("/", "-");
        name += extension;

        return name;
    }, [props.lookups, props.selectedParams.reportingPeriodId, props.selectedParams.watershedGroupId]);

    const downloadFile = useCallback(async (endpoint: string, fileName: string, errorMessage?: string) => {
        const toastId = toast.info("Downloading...");
        try {
            await Download(endpoint, auth.user?.access_token, fileName);
        } catch (err) {
            toast.error(errorMessage ?? "Failed to download file");
        } finally {
            toast.dismiss(toastId);
        }
    }, [auth]);

    const bulkDownloadWatershedControlMeasures = useCallback(async () => {
        let group = props.lookups.lutWatershedManagementGroup.find(wmg => wmg.id === props.selectedParams.watershedGroupId);
        const endpoint = Config.api_endpoint + `bmp/wcmbulkdownload?watershedManagementGroups[0]=${group.internalName}`;

        let toastId = toast.info("Downloading...", { containerId: "manage-project-data-toast-container", autoClose: false });
        try {
            await Download(endpoint, auth.user?.access_token, "Watershed Control Measures.xlsx");
        } catch (err) {
            toast.error("Failed to download file: " + err, { containerId: "manage-project-data-toast-container" });
        } finally {
            toast.dismiss(toastId);
        }
    }, [auth, props.lookups.lutWatershedManagementGroup, props.selectedParams.watershedGroupId]);

    const bulkDownloadNewAndRedevelopmentProjects = useCallback(async () => {
        let group = props.lookups.lutWatershedManagementGroup.find(wmg => wmg.id === props.selectedParams.watershedGroupId);
        const endpoint = Config.api_endpoint + `bmp/newredevelopmentdownload?watershedManagementGroups[0]=${group.internalName}`;

        let toastId = toast.info("Downloading...", { containerId: "manage-project-data-toast-container", autoClose: false });
        try {
            await Download(endpoint, auth.user?.access_token, "New & Redevelopment.xlsx");
        } catch (err) {
            toast.error("Failed to download file: " + err, { containerId: "manage-project-data-toast-container" });
        } finally {
            toast.dismiss(toastId);
        }
    }, [auth, props.lookups.lutWatershedManagementGroup, props.selectedParams.watershedGroupId]);

    const Continue = () => {
        props.setActiveItem('1_7')
        props.scrollFunction()
    }

    return (
        <div className="build-watershed-form-subpage  wg-reporting-outputs">

            <ToastContainer position="top-center" />
            <Translation>
                {
                    t => <>
                        <div className="subpage-container">
                            <div className="subpage-header-container">
                                <div className="subpage-header-1">Reporting Outputs Submitted by Jurisdictions</div>
                                <div className="subpage-header-2 pb-2">
                                    <div className="text">
                                        WRAMPS compiles key outputs for the WMP Progress Report Form. Below are the key outputs generated by each individual jurisdiction in the Group. The timestamp shows the time of the most recent submittal by each jurisdiction for this reporting period.
                                    </div>

                                    <div className="table-header-div">
                                        <div className="subpage-header-1">Step 1: Outputs by Jurisdictions</div>

                                    </div>
                                    <div className="reporting-output-table">
                                        <Table celled fixed>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Jurisdiction</Table.HeaderCell>
                                                    <Table.HeaderCell>WMP Progress Report Form (.docx)</Table.HeaderCell>
                                                    <Table.HeaderCell>Compiled attachments by section (.zip)</Table.HeaderCell>
                                                    <Table.HeaderCell>Output for Tables 1a, 1b and 1c (.xlsx)</Table.HeaderCell>
                                                    <Table.HeaderCell>Time of most recent submittal</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {tableReportForms && tableReportForms.map((t, i) => {
                                                    if (t.signed) {
                                                        return (
                                                            <Table.Row key={i}>
                                                                <Table.Cell>{props.lookups.getJurisdictionById(t.jurisdictionId)}</Table.Cell>
                                                                <Table.Cell onClick={() => downloadFile(Config.api_endpoint + "report/word/jurisdiction/" + t.jurisdictionId + "/" + props.selectedParams.watershedGroupId + "/" + props.selectedParams.reportingPeriodId, getJurisdictionFileName(t.jurisdictionId, ".docx"))} className="icon-cell"><Icon className="file word outline" name="chart bar" /></Table.Cell>
                                                                <Table.Cell onClick={() => downloadFile(Config.api_endpoint + "zip/jurisdiction/" + t.jurisdictionId + "/" + props.selectedParams.watershedGroupId + "/" + props.selectedParams.reportingPeriodId, getJurisdictionFileName(t.jurisdictionId, ".zip"))} className="icon-cell"><Icon className="file pdf outline" name="chart bar" /></Table.Cell>
                                                                <Table.Cell onClick={() => downloadFile(Config.api_endpoint + "report/excel/jurisdiction/" + t.jurisdictionId + "/" + props.selectedParams.watershedGroupId + "/" + props.selectedParams.reportingPeriodId, getJurisdictionFileName(t.jurisdictionId, ".xlsx"))} className="icon-cell"><Icon className="file excel outline" name="chart bar" /></Table.Cell>
                                                                <Table.Cell>{t.signDate.toDateString()}</Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    } else {
                                                        return (
                                                            <Table.Row key={i}>
                                                                <Table.Cell>{props.lookups.getJurisdictionById(t.jurisdictionId)}</Table.Cell>
                                                                <Table.Cell >N/A</Table.Cell>
                                                                <Table.Cell >N/A</Table.Cell>
                                                                <Table.Cell >N/A</Table.Cell>
                                                                <Table.Cell>Not yet submitted</Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    }
                                                })
                                                }

                                            </Table.Body>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                            <div className="subpage-header-container">
                                <div className="subpage-header-2">
                                    <div className="subpage-header-1">Step 2: Generate Compiled Reporting Outputs</div>

                                    WRAMPS can compile the jurisdictional outputs into a set of combined outputs for the Group.  After reviewing the reporting outputs and verifying their time stamp, use the button below to generate the key compiled Group outputs for the WMP Progress Report Form.  As a Group Lead, you may change the report status above to "Final" to prevent any additional edits by the jurisdictions.

                                    {/* TODO re implement these after the demo  */}
                                    {/* <GroupSubmitStatus lookups={props.lookups} selectedParams={props.selectedParams} />
                                        <ButtonComponent label="Download Excel Report" onBtnClick={downloadGroupExcel} />
                                        <ButtonComponent label="Download Report Attachments" onBtnClick={downloadZipFile} /> */}
                                    <div className="btn-div">
                                        <ButtonComponent
                                            onBtnClick={() => { setShowOutputs1(true); setTimeStampOfGen(new Date().toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })) }}
                                            label="Generate Output"
                                            theme="primaryBtn"
                                        />

                                        {timeStampOfGen &&
                                            <div className="submitted">
                                                Generated On: &nbsp;&nbsp;{timeStampOfGen}
                                            </div>
                                        }
                                    </div>

                                    <div className="outputs">
                                        {
                                            showOutputs1 &&
                                            <>
                                                <div className="outputs-title">Outputs:</div>
                                                <ul>
                                                    <li className="output-item">
                                                        <button onClick={() => downloadFile(wordOutputEndoint, getGroupFileName(".docx"))}>
                                                            Progress Report Form with text entries (.docx)
                                                        </button>
                                                    </li>
                                                    <li className="output-item">
                                                        <button onClick={() => downloadFile(zipOutputEndpoint, getGroupFileName(".zip"), "Failed to download file, please ensure at least one of the forms has an attached file.")}>
                                                            Compiled attachments by section  (.zip)
                                                        </button>
                                                    </li>
                                                    <li className="output-item">
                                                        <button onClick={() => downloadFile(excelOutputEndpoint, getGroupFileName(".xlsx"))}>
                                                            Output for Tables 1a, 1b, 1c and Sections 1.2a, 1.3a (.xlsx)
                                                        </button>
                                                    </li>
                                                </ul>
                                            </>
                                        }
                                    </div>
                                    <div className="btn-div mt-2">
                                        <p className="mb-1">
                                            <i>Supporting Data</i>: The buttons below may be used to bulk download the individual project data used to generate the compiled reporting outputs above.
                                        </p>

                                        <div>
                                            <div className="float-left mr-2">
                                                <ButtonComponent
                                                    onBtnClick={bulkDownloadWatershedControlMeasures}
                                                    label="Bulk Download Watershed Control Measures"
                                                    theme="primaryBtn mr-w" />
                                            </div>
                                            <ButtonComponent
                                                onBtnClick={bulkDownloadNewAndRedevelopmentProjects}
                                                label="Bulk Download New and Redevelopment Projects"
                                                theme="primaryBtn" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <WatershedGroupReportingPublicOutputs
                                lookups={props.lookups}
                                selectedParams={props.selectedParams}
                            />
                            <div className="subpage-header-container">
                                <div className="subpage-header-2">
                                    <div className="subpage-header-1">Step 4: Configure, Preview, and Publish Public Dashboard On Next Tabs</div>
                                    <div className="text pb-point5">
                                        In addition to posting the WMP Progress Report Form, WRAMPS2 can also publish an interactive dashboard to increase public understanding and accessibility.  See the next tabs for dashboard publishing options.
                                    </div>
                                </div>
                            </div>
                            <div className="save-btn-div">
                                <ButtonComponent
                                    onBtnClick={() => Continue()}
                                    label={"Continue to Configure Public Elements"}
                                    theme="primaryBtn"
                                />
                            </div>
                        </div>
                    </>
                }
            </Translation>
        </div>
    )
}
